import logo from "./logo.svg";
import React from "react";
import "./App.css";
import backgroundVideo from "./background.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./TextInput.js";
import ImageLoader from "./ImageLoader";

function App() {
  return (    
    <div className="App">
      <header className="App-header">
        <div className="top-box">
          {" "}
          <h1>Dale Short</h1>
          <p className="explanation">
            I made a bunch of ok things at The Recurse Center
          </p>
          <p className="explanation">This is not one of them</p>
        </div>
        <div className="mid-box">
          <div className="mid-inside-rectangle">
            <ImageLoader></ImageLoader>
          </div>
        </div>
        <div className="video-box">
          <div class="video-container">
            <video autoPlay loop muted id="video">
              <source src={backgroundVideo} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="bottom-box">
          <div className="arrow-box">
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </div>
          <div className="center-message">
            <p>
              Resize the window to select a project. Type "<TextInput />" to
              visit that project. If you're on mobile, find a computer.
            </p>
          </div>
          <div className="arrow-box">
            <FontAwesomeIcon icon={faArrowRightLong} />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
