import React from "react";
import smartScale from "./smartscale.png";
import reactdoku from "./reactdoku.png";
import fulcrum from "./fulcrum.png";
import todo from "./todo.png";
import rain from "./rain.jpg";

import useWindowDimensions from "./window";

function ImageLoader() {
  const { height, width } = useWindowDimensions();

  if (1000 < width && width < 1200) {
    return (
      <div
        className="image-container"
        style={{ backgroundImage: `url(${smartScale})` }}
      >
        <div className="image-caption">Smart Scale</div>
      </div>
    );
  } else if (850 < width && width < 1000) {

    return (
        <div
          className="image-container"
          style={{ backgroundImage: `url(${rain})` }}
        >
          <div className="image-caption">Smart Rain Barrel</div>
        </div>
      );
  } else if (600 < width && width < 850) {
    return (
      <div
        className="image-container"
        style={{ backgroundImage: `url(${reactdoku})` }}
      >
        <div className="image-caption">ReactDoku</div>
      </div>
    );
  } else if (width < 600) {
    return (
      <div
        className="image-container"
        style={{ backgroundImage: `url(${todo})` }}
      >
        <div className="image-caption">To do App</div>
      </div>
    );
  }
  if (width > 1200) {
    return (
        <div
          className="image-container"
          style={{ backgroundImage: `url(${fulcrum})` }}
        >
          <div className="image-caption">Fulcrum Focus</div>
        </div>
      );
  }
}

export default ImageLoader;
