import React from "react";
import { useCallback, useEffect, useState, useReducer } from "react";
import useWindowDimensions from "./window";

function TextInput() {
    const { height, width } = useWindowDimensions();

    function routeUrl(){
        console.log("routing function before comparison")
        console.log('widht is:', width)
        if (1000 < width && width < 1200) {
            console.log('redirect to fulcrum')
            window.location.href = 'https://programmerd.com/scale/'
          } else if (850 < width && width < 1000) {
            window.location.href = 'https://mechied.com/index.php/portfolio/smart-rain-barrel/'
          } else if (600 < width && width < 850) {
            window.location.href = 'https://programmerd.com/sudokuapp/'
          } else if (width < 600) {
            window.location.href = 'https://programmerd.com/todo/'
          }
          if (width > 1200) {
            window.location.href = 'https://programmerd.com/fulcrum/'

          }
        }
    

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      key: "",
      string: "",
      newKey: null,
    }
  );

  function returnHighlightChar(char) {
    return <span className="highlight-text">{char}</span>;
  }
  function returnNormalChar(char){
    return <span>{char}</span>
  }
  
  function compareString(){
    let testString = 'ur hired';
    let count = 0;
    let text = Array();
    let matching = true;
    console.log(state.string)
    for(let char of testString){
        console.log("char is:", char)
        console.log("compared to:",state.string.charAt(count) )
        if(char == state.string.charAt(count) && matching == true){
            text.push(returnHighlightChar(char))
            if (count ==7){
                console.log("routing in compare")
                routeUrl();
            }
        } else{
            text.push(returnNormalChar(char))
            matching = false;
        }
        count ++;
    }
    return text
  }

  const handleKeypress = (event) => {
    // let copyText = state.string
    // console.log('text is:', state.string)
    // console.log("event key:", event.key);

    // const newText = copyText+ "a" ;
    // console.log("new text:", newText);
    console.log(event.key);
    setState({ key: event.key, newKey: true });
  };

  useEffect(() => {
    console.log("update string");
    if (state.newKey == true) {
      let newText = state.string + state.key;
        let isCorrect = true
        let testString = 'ur hired';
        let count =0;
        for(let char of newText){
            if(char != testString.charAt(count)){
                isCorrect = false
            }
            count ++;
        }
        if(isCorrect == false){
            newText = ''
        }

      setState({ string: newText, newKey: false });
    }
  }, [state.key, state.newKey]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress, true);

    return () => {
      document.removeEventListener("keydown", handleKeypress, true);
    };
  }, []);

  return <>{compareString()}</>;
}

export default TextInput;
